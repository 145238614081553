var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { prop: "originOrder" } },
        [
          _c("span", [_vm._v("售后订单")]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.oneOrMore == "1",
                  expression: "form.oneOrMore == '1'"
                }
              ],
              attrs: {
                type: "text",
                icon: "el-icon-circle-plus-outline",
                disabled: _vm.orderBtnDisabled
              },
              on: { click: _vm.showOrderModal }
            },
            [_vm._v("添加订单")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                stripe: "",
                size: "mini",
                "element-loading-text": "拼命加载中",
                "row-style": { height: "48px" },
                "cell-style": { padding: "0px" },
                "header-cell-style": { background: "#e5f2ff" },
                "header-cell-class-name": "normal-table-header",
                data: _vm.orderTable
              }
            },
            [
              _vm._l(_vm.orderColumns, function(item) {
                return [
                  item.prop === "payType"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.payTypeMap[row.payType]) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "allPay"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.allPayMap[row.allPay]) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "goodsName"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "180",
                          fixed: item.fixed
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(" " + _vm._s(row.goodsName) + " "),
                                  row.commodityName
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "#337aff",
                                            "margin-top": "6px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.commodityName) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "goodsCategoryName"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "180px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.goodsCategoryParentName
                                          ? row.goodsCategoryParentName +
                                              "-" +
                                              row.goodsCategoryName
                                          : row.goodsCategoryName
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "goodsSeriesName"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "180px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.goodsSeriesParentName
                                          ? row.goodsSeriesParentName +
                                              "-" +
                                              row.goodsSeriesName
                                          : row.goodsSeriesName
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "orderStatus"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderStatusMap[row.orderStatus]
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "totalMoney"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " + _vm._s(row.totalMoney || 0) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "afterSaleTypes"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.afterSaleTypes || "无") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "createTime"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: item.label,
                          width: "160"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          row.createTime,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "installmentType"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.installmentType != 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.installmentTypeMap[
                                                row.installmentType
                                              ]
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [_vm._v("- -")])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : item.prop === "tradeSerialNumber"
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.installmentType != 0
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.tradeSerialNumber) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [_vm._v("- -")])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          fixed: item.fixed
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  [
                                    "productName",
                                    "goodsSpecName",
                                    "goodsTypeName",
                                    "goodsSeriesName",
                                    "signSchoolName",
                                    "teacherRecruitName",
                                    "customerCode"
                                  ].includes(item.prop)
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("empty")(
                                              row[item.prop],
                                              "--"
                                            )
                                          )
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(row[item.prop]))
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                ]
              }),
              _vm.orderTable.length > 1
                ? _c("el-table-column", {
                    key: "action",
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "操作",
                      width: "180",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.$index !== 0
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "error" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onDeleteRow(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1181556229
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }